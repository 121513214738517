define('clientCache',
    ['jquery', 'clientStorage', 'configLoader', 'util', 'enums', 'logger'],
    function ($, clientStorage, configLoader, modularisUtil, enums, logger) {
        'use strict';

        /**
         * Client cache object.
         * @namespace clientCache
         * @memberof modularis.web
         */
        var clientCache = (function () {

            var availableServers = [];
            var activeServer = null;
            var uiDefID = null;
            var session = null;
            var deviceName = null;
            var sessionCookieName = 'modularisRememberUser';
            var activeServerName = 'activeServerName';

            var getServer = function (serverName) {
                var servers = getServers();
                for (var index = 0; index < servers.length; index++) {
                    if (servers[index].name === serverName) {
                        return servers[index];
                    }
                }
                return null;
            };

            var getServers = function () {
                if (availableServers.length === 0 && configLoader.appConfig) {
                    availableServers = configLoader.appConfig.serverConfigs;
                }
                return availableServers;
            };


            var storeActiveServer = function (newActiveServer, serverName) {

                if (newActiveServer != null) {
                    activeServer = newActiveServer;
                    clientStorage.setValue(activeServerName, serverName);

                    return true;
                }

                return false;
            };

            return {
                 /**
                 * Returns the active server. The servers are configured in the application setting in the property serverConfigs.
                 * <br> Check out this {@link http://support.modularis.com/pages/viewpage.action?title=Application+settings&spaceKey=AKB | link} 
                 * for more information about the {@link http://support.modularis.com/pages/viewpage.action?title=Application+settings&spaceKey=AKB | Application settings}.
                 * 
                 * @memberOf modularis.web.clientCache
                 * @returns {object} A plain object with the information of the server that is being used. 
                 */
                getActiveServer: function () {
                    if (activeServer == null) {
                        var newActiveServerName = clientStorage.getValue(activeServerName);
                        if (!newActiveServerName && configLoader.appConfig) {
                            newActiveServerName = configLoader.appConfig.defaultServerName;
                        }

                        if (newActiveServerName) {
                            this.setActiveServer(newActiveServerName);
                        }
                    }
                    return activeServer;
                },

                 /**
                 * Sets the active server. The servers are configured in the application setting in the property serverConfigs.
                 * <br> Check out this {@link http://support.modularis.com/pages/viewpage.action?title=Application+settings&spaceKey=AKB | link} 
                 * for more information about the {@link http://support.modularis.com/pages/viewpage.action?title=Application+settings&spaceKey=AKB | Application settings}.
                 * @memberOf modularis.web.clientCache
                 * @param {string} serverName - The name of the server configured in the application setting to be set as active.
                 */
                setActiveServer: function (serverName) {
                    var newActiveServer = getServer(serverName);

                    if (!storeActiveServer(newActiveServer, serverName)) {

                        //Remove the variable in cache to reload the server configuration. This prevent that  app fail if changes the config file.
                        clientStorage.removeValue(activeServerName);

                        if (configLoader.appConfig) {
                            var currentServerName = configLoader.appConfig.defaultServerName;

                            newActiveServer = getServer(currentServerName);

                            storeActiveServer(newActiveServer, currentServerName);
                        }
                    }
                },

                /**
                 * Returns the active server customerID property. 
                 * <br> Check out this {@link http://support.modularis.com/pages/viewpage.action?title=Application+settings&spaceKey=AKB | link} 
                 * for more information about the {@link http://support.modularis.com/pages/viewpage.action?title=Application+settings&spaceKey=AKB | Application settings}.
                 * @memberOf modularis.web.clientCache
                 * @returns {string} 
                 */
                getActiveCustomerID: function () {
                    return this.getActiveServer().customerID;
                },

                 /**
                 * Returns the session object.
                 * 
                 * @memberOf modularis.web.clientCache
                 * @returns {object} An object with the information of the session.
                 */
                getSession: function () {
                    if (session == null) {
                        session = clientStorage.getValue('session');
                    }
                    return session;
                },

                /**
                 * Returns the session ID.
                 * 
                 * @memberOf modularis.web.clientCache
                 * @returns {string} The unique session identifier.
                 */
                getSessionID: function () {
                    var sessionID = null;
                    var session = this.getSession();
                    if (session != null) {
                        sessionID = session.SessionID;
                    }
                    return sessionID;
                },

                /**
                 * Returns the user ID.
                 * 
                 * @memberOf modularis.web.clientCache
                 * @returns {number} The unique user identifier.
                 */
                getUserID: function () {
                    var userID = null;
                    var currentSession = this.getSession();
                    if (currentSession != null) {
                        userID = currentSession.UserID;
                    }
                    return userID;
                },

                 /**
                 * Returns the value of a variable stored  in the session if exist. otherwise, returns null.
                 * 
                 * @memberOf modularis.web.clientCache
                 * @param {string} variableName - The variable name to be consulted.
                 * @returns {string|null}
                 */
                getSessionVariable: function (variableName) {

                    var variables = this.getSession().Variables.Items;
                    for (var index = 0; index < variables.length; index++) {
                        var pair = variables[index];
                        if (pair.Key === variableName) {
                            return pair.Value;
                        }
                    }
                    return null;

                },

                 /**
                 * Sets the session.
                 * 
                 * @memberOf modularis.web.clientCache
                 * @param {object} newSession - A session object.
                 */
                setSession: function (newSession) {
                    clientStorage.setValue('session', newSession);
                    session = newSession;
                    if (newSession == null && this.isSessionTenantSelected()) {
                        this.setSessionTenantSelected(false);
                    }
                },

                 /**
                 * Returns the application uiDefID.
                 * <br> This value is configured in the {@link http://support.modularis.com/pages/viewpage.action?title=Application+settings&spaceKey=AKB | Application settings}. Check out this {@link http://support.modularis.com/pages/viewpage.action?title=Application+settings&spaceKey=AKB | link} 
                 * for more information.
                 * @memberOf modularis.web.clientCache
                 * @returns {string} 
                 */
                getUIDefID: function () {
                    if (uiDefID == null) {
                        uiDefID = configLoader.appConfig.uiDefID;
                    }
                    return uiDefID;
                },

                /**
                 * Returns the application systemName.
                 * <br> This value is configured in the {@link http://support.modularis.com/pages/viewpage.action?title=Application+settings&spaceKey=AKB | Application settings}. Check out this {@link http://support.modularis.com/pages/viewpage.action?title=Application+settings&spaceKey=AKB | link} 
                 * for more information.
                 * @memberOf modularis.web.clientCache
                 * @returns {string} 
                 */
                getSystemName: function () {
                    return configLoader.appConfig.systemName;
                },

                /** 
                 * Establishes if the current user is authenticated.
                 * @memberOf modularis.web.clientCache
                 * @returns {boolean}
                 * */
                isAuthenticated: function () {
                    var that = this;
                    var validSessionID = modularisUtil.isDefined(that.getSessionID()) && that.getSessionID() !== null && that.getSessionID().length > 0 && that.getSessionID() !== 'null';
                    var result = (that.getActiveServer() !== null) && (validSessionID === true) && (that.getSession().Status === enums.authenticationStatus.authenticationSuccessful);
                    return result;
                },

                /**
                 * Stores the session in a cookie (the session cookie).
                 * 
                 * @memberOf modularis.web.clientCache
                 */
                storeSessionCookie: function () {
                    var that = this;
                    if (that.sessionCookieEnabled()) {
                        var cookieValue = that.getSessionID() + '|' + that.getDeviceName();
                        that.setCookie(sessionCookieName, cookieValue, configLoader.appConfig.sessionCookie.expirationDays);
                    } else {
                        var exceptionMessage = 'allowSessionCookie must be enabled in app/config file.';

                        logger.error(exceptionMessage);
                        throw Error(exceptionMessage);
                    }
                },

                 /**
                 * Removes the session  cookie.
                 * 
                 * @memberOf modularis.web.clientCache
                 */
                deleteSessionCookie: function () {
                    this.deleteCookie(sessionCookieName);
                },

                /** 
                 * Indicates if the session cookie configuration is enabled.
                 * @memberOf modularis.web.clientCache
                 * @returns {boolean}
                 * */
                sessionCookieEnabled: function () {
                    var result = ('sessionCookie' in configLoader.appConfig) && ('enabled' in configLoader.appConfig.sessionCookie) && configLoader.appConfig.sessionCookie.enabled;
                    return result;
                },

                /** 
                 * Returns the session cookie data.
                 * @memberOf modularis.web.clientCache
                 * @returns {object} A plain key|value object with the next data: sessionID, deviceName.
                 * */
                getSessionCookieData: function () {
                    var tokensLength = 2;

                    var cookie = this.getCookie(sessionCookieName);

                    if (cookie == null) {
                        return null;
                    }

                    var tokens = cookie.split('|');
                    if (tokens.length === tokensLength) {
                        var result = {
                            sessionID: tokens[0],
                            deviceName: tokens[1]
                        };
                        return result;
                    }
                    var exceptionMessage = 'Invalid session cookie';
                    logger.error(exceptionMessage);
                    throw Error(exceptionMessage);
                },

                recoverSessionCallback: null,

                //#region Device name

                /** 
                 * Gets the current device name.
                 * @memberOf modularis.web.clientCache
                 * @returns {string}
                 */
                getDeviceName: function () {
                    if (deviceName == null) {
                        deviceName = clientStorage.getValue('deviceName');
                    }
                    return deviceName;
                },

                /** 
                 * Sets the device name.
                 * @param {string} newDeviceName - New device name
                 * @memberOf modularis.web.clientCache
                 */
                setDeviceName: function (newDeviceName) {
                    clientStorage.setValue('deviceName', newDeviceName);
                    deviceName = newDeviceName;
                },

                 /** 
                 * Removes the current device name.
                 * @memberOf modularis.web.clientCache
                 * */
                removeDeviceName: function () {
                    clientStorage.removeValue('deviceName');
                },

                //#endregion

                //#region Tenant-related functions

                /**
                 * Gets the value of the active tenant ID.
                 * @returns {string} The active tenant ID. 
                 * 
                 * @memberOf modularis.web.clientCache
                 */
                getActiveTenantID: function () {
                    var activeTenantID = null;
                    var currentSession = this.getSession();
                    if (currentSession != null) {
                        activeTenantID = currentSession.ActiveTenantID;
                    }
                    return activeTenantID;
                },

                /**
                 * Sets the active tenant ID.
                 * @param {string} tenantId - Tenant ID.
                 * 
                 * @memberOf modularis.web.clientCache
                 */
                setActiveTenantID: function (tenantId) {
                    var currentSession = this.getSession();
                    if (currentSession != null) {
                        currentSession.ActiveTenantID = tenantId;
                    }
                },

                /**
                 * Returns true if the user already made a Tenant selection in the current session.
                 * @returns {boolean}  
                 * 
                 * @memberOf modularis.web.clientCache
                 */
                isSessionTenantSelected: function () {
                    return clientStorage.getValue('sessionTenantSelected');
                },

                /**
                 * Sets the state of the tenant selection for the current session.
                 * @param {boolean} isSelected - True if the tenant selection has been made.
                 * 
                 * @memberOf modularis.web.clientCache
                 */
                setSessionTenantSelected: function (isSelected) {
                    clientStorage.setValue('sessionTenantSelected', isSelected);
                },
                //#endregion

                //#region Cookie-related functions

                /**
                 * Sets a cookie associated to the current document.
                 * @param {string} name - Cookie name.
                 * @param {string|number|boolean} value - Cookie value.
                 * @param {number} expirationDays - Number of days the cookie will be available.
                 * 
                 * @memberOf modularis.web.clientCache
                 */
                setCookie: function (name, value, expirationDays) {
                    var millisecondsByDay = 86400000;//24 * 60 * 60 * 1000
                    var milliseconds = expirationDays * millisecondsByDay;
                    var currentDate = new Date();
                    currentDate.setTime(currentDate.getTime() + milliseconds);

                    var format = '{0}={1}; expires={2}';
                    document.cookie = String.format(format, name, value, currentDate.toGMTString());
                },

                /**
                 * Gets the value of a cookie associated to the current document, based on the cookie name.
                 * @param {string} name - Cookie name.
                 * @returns {string} The cookie value. 
                 * 
                 * @memberOf modularis.web.clientCache
                 */
                getCookie: function (name) {
                    var cookies = document.cookie.split(';');
                    for (var index = 0; index < cookies.length; index++) {
                        var cookie = cookies[index];

                        //Remove white spaces before cookie name
                        while (cookie.charAt(0) === ' ') {
                            cookie = cookie.substring(1);
                        }

                        if (cookie.indexOf(name + '=') === 0) {
                            return cookie.substring(name.length + 1, cookie.length);
                        }
                    }
                    return null;
                },

                /**
                 * Deletes a cookie associated to the current document.
                 * @param {string} name - Cookie name.
                 * 
                 * @memberOf modularis.web.clientCache
                 */
                deleteCookie: function (name) {
                    this.setCookie(name, '', -1);
                },
                isLegacyWebServicesEnabled: function () {
                    var legacyWebServiceEnabled = this.getSessionVariable('LegacyWebServicesEnabled');
                    
                    return legacyWebServiceEnabled === undefined || legacyWebServiceEnabled === null || legacyWebServiceEnabled === true ;
                }

                //#endregion
            };

        })();

        return clientCache;
    }
);
