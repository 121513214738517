define('modularis/modularisApp',
    ['jquery', 'kendo', 'modularisBase/extensions', 'RESTSecurityWebServiceProxy', 'RESTNotificationWebServiceProxy', 'modularis/web/widgetLoader', 'clientStorage',
        'clientCache', 'modularisRoutingManager', 'modularisStyleSheetManager', 'localization', 'configLoader', 'entityHelper',
        'modularisGeneral/jQueryUtil', 'util', 'enums', 'logger'],
    function ($, kendo, extensions, securityServiceProxy, notificationServiceProxy, widgetLoader, clientStorage,
        clientCache, routingManager, styleSheetManager, localization, configLoader, entityHelper,
        jQueryUtil, modularisUtil, enums, logger) {
        'use strict';

        var initDefaultOptions = {
            validateSessionHandler: null,
            onRouteChange: null,
            onBeforeNavigate: null
        };

        var handleSessionTimeout = function () {
            //Configure session timeout
            if (configLoader.containsConfigValue('handleSessionTimeout') && configLoader.appConfig.handleSessionTimeout) {

                securityServiceProxy.bind(enums.securityEventName.authenticationSuccessful, function (event) {

                    var session = event.session;
                    var idleTime = 0,
                        interval = 60000;
                    //minutes
                    var timeout = session.Timeout;

                    //Increment idle time every minute
                    var intervalID = setInterval(function () {
                        idleTime += 1;
                        if (idleTime >= timeout) {

                            //Unbind event handlers for session timeout
                            $(document).unbind('mousemove.resetIdleTime');
                            $(document).unbind('click.resetIdleTime');
                            $(document).unbind('keydown.resetIdleTime');
                            clearInterval(intervalID);

                            clientStorage.setValue(enums.sessionExpired, true);
                            if (modularisApp.logOffViewModel != null) {
                                modularisApp.logOffViewModel.handleRoute();
                            } else {
                                //View model not found, so redirect to index.
                                logger.info('logOffViewModel module not found.');
                                window.location.href = '/';
                            }
                        }
                    }, interval);

                    var resetIdleTime = function () {
                        idleTime = 0;
                    };

                    //Events that will be handled to reset idle time
                    $(document).bind('mousemove.resetIdleTime', resetIdleTime);
                    $(document).bind('click.resetIdleTime', resetIdleTime);
                    $(document).bind('keydown.resetIdleTime', resetIdleTime);

                });

            }
        };

        var configureDeviceName = function () {
            //Checks for the device name. In case it doesn't exist, a new one is created.

            var deviceName = clientCache.getDeviceName();
            if (deviceName == null) {
                deviceName = modularisUtil.newGuid();
                clientCache.setDeviceName(deviceName);
            }
        };

        var handleNotificationServiceClose = function () {
            //Closes/unbind any active connection or event handler when user logs out.
            if (configLoader.containsConfigValue('realTimeNotifications') && configLoader.appConfig.realTimeNotifications.enabled) {
                securityServiceProxy.bind(enums.securityEventName.logOff, function () {
                    notificationServiceProxy.closeConnection();
                });
            }
        };

        var modularisApp = {

            logOffViewModel: null,

            version: '@@ReleaseVersion@@',

            init: function (initOptions) {

                var options = {};
                $.extend(options, initDefaultOptions, initOptions);

                //Configure platform bits related to jQuery
                jQueryUtil.configure();

                configLoader.init(options.appConfig);
                clientCache.recoverSessionCallback = options.recoverSessionCallback;
                this.logOffViewModel = options.logOffViewModel;

                handleSessionTimeout();
                configureDeviceName();
                handleNotificationServiceClose();

                if (options.entityTypes) {
                    entityHelper.initialize(options.entityTypes);
                }

                //Load stylesheets
                styleSheetManager.loadStyleSheets(function () {

                    //Initialize localization settings
                    localization.initialize(function () {

                        //handleLocalizationCleanup();
                        widgetLoader.init();

                        /**Initialize routes after the widgets has been loaded.
                         In that way, the first page load Modularis widgets correctly in case it embeds 
                         some of them. */
                        var routingManagerOptions = {
                            validateSessionHandler: options.validateSessionHandler,
                            onRouteChange: options.onRouteChange,
                            onBeforeNavigate: options.onBeforeNavigate
                        };
                        routingManager.init(options.routesDefinition.routes, routingManagerOptions);

                        modularisUtil.notify(options.callback);

                    });

                });

            }
        };

        return modularisApp;

    }
);
